import React, { useCallback, useState } from 'react'
import { navigate } from 'gatsby'
import { Button, Icon, Label, Modal } from 'semantic-ui-react'
import SwiperCore, { Pagination, Mousewheel, A11y, Autoplay, Navigation, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import SEO from '../../components/SEO'
import LandingPageLeadForm from '../../components/LandingPageLeadForm'
import Vimeo from '../../components/Vimeo'

import Logo from '../../images/logos/contego-logo.png'

import { callHandler } from '../../ultilities'
import Feedbacks from '../../components/Feedbacks'

SwiperCore.use([Pagination, A11y, Mousewheel, Autoplay, Navigation, Scrollbar])

const FEATURE_CHAIRS = [
  { title: 'MAGNA', tags: ['LUXURY'], id: 494604239, frameRatio: 4 / 3 },
  {
    title: 'V-SPORT N',
    tags: ['BEST SELLING'],
    id: 494603987,
    frameRatio: 4 / 3
  },
  { title: 'V-SPORT T', tags: ['BEST SELLING'], id: 494603810, frameRatio: 4 / 3 },
  { title: 'VIP', tags: ['LUXURY'], id: 494604630, frameRatio: 4 / 3 }
]

const FEATURES = [
  {
    id: 495058903,
    frameRatio: 9 / 16
  },
  { id: 495058746, frameRatio: 9 / 16 },
  { id: 495058635, frameRatio: 9 / 16 },
  { id: 495059196, frameRatio: 9 / 16 }
]

const ProductDetails = () => {
  const handleCallNow = e => {
    const currentHour = Number(
      new Date()
        .toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hour12: false })
        .split(':')[0]
    )

    if (currentHour >= 8 && currentHour <= 21) {
      callHandler(e.currentTarget.dataset.phoneNumber)
      navigate('/vi/thank-you')
    } else {
      // eslint-disable-next-line no-alert
      window.alert(
        'Liên lạc ngoài giờ làm việc!!! Anh chị vui lòng để lại thông tin bên dưới, nhân viên tư vấn sẽ liên lạc. Giờ làm việc của nhân viên tư vấn 8AM-9PM (Giờ California)'
      )
    }
  }

  const handleSuccess = useCallback(() => {
    navigate('/vi/thank-you')
  }, [])

  return (
    <>
      <div className="container">
        <SEO
          title="NHỮNG DÒNG GHẾ ĐẸP VÀ SANG TRỌNG CỦA CONTÉGO?"
          description="Vì sao đầu tư ghế pedicure spa massage chair của CONTEGO là tốt nhất."
          meta={[
            {
              name: 'keywords',
              content: 'GIÁ TRỊ ghế Contégo, lợi ích ghế Contego, hữu ích, lợi ích, ghế Contego'
            }
          ]}
        />
        <div className="row">
          <div className="col py-4 ld-header">
            <img src={Logo} width="120px" height="auto" alt="contego company logo" />

            <h4 className="my-auto text-center px-4">
              NHỮNG DÒNG GHẾ ĐẸP VÀ SANG TRỌNG CỦA CONTÉGO
            </h4>
          </div>
        </div>

        <div className="row">
          <div className="col px-0">
            <Swiper
              slidesPerView={1}
              navigation
              spaceBetween={10}
              centeredSlides
              scrollbar
              pagination={{ type: 'fraction', clickable: true }}
              mousewheel={{ forceToAxis: true }}
              className="py-4 bg-alabaster"
            >
              {FEATURE_CHAIRS.map(item => {
                return (
                  <SwiperSlide key={item.title} className="bg-light rounded shadow-sm">
                    <Vimeo
                      title={item.id}
                      id={item.id}
                      frameRatio={item.frameRatio}
                      threshold={[0.5, 1]}
                    />
                  </SwiperSlide>
                )
              })}
              <br /> <br />
            </Swiper>
          </div>
        </div>
        <div className="row">
          <div className="col py-4">
            <h2 className="py-4 text-center">
              Không những sang trọng mà còn hữu ích cho công việc của các anh chị thợ.
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col px-0">
            <Swiper
              slidesPerView={1}
              navigation
              spaceBetween={10}
              pagination={{ type: 'fraction', clickable: true }}
              mousewheel={{ forceToAxis: true }}
              scrollbar
              className="bg-alabaster py-4 px-2"
            >
              {FEATURES.map(item => {
                return (
                  <SwiperSlide key={item.title}>
                    <Vimeo
                      title={item.id}
                      id={item.id}
                      frameRatio={item.frameRatio}
                      threshold={[0.5, 1]}
                    />
                  </SwiperSlide>
                )
              })}
              <br /> <br />
            </Swiper>
          </div>
        </div>
        <div className="row bg-light my-2 rounded shadow-sm">
          <div className="col py-4">
            <h2 className="py-4 text-center">Ghế Spa Contego hữu ích với nhiều tính năng giúp </h2>
            <div className="d-flex justify-content-center flex-wrap">
              <Label className="text-secondary m-2">
                <h3>Bảo Vệ Khách</h3>
              </Label>{' '}
              <Label className="text-secondary m-2">
                <h3>Tiện Cho Thợ</h3>
              </Label>{' '}
              <Label className="text-secondary m-2">
                <h3>Tăng Lợi Nhuận Cho Tiệm</h3>
              </Label>
            </div>
          </div>
        </div>

        <h2>
          <strong className="text-blue">Để được tư vấn thêm </strong>
          <Button
            className="call-now-btn rounded-pill text-white shadow-sm"
            data-phone-number="7148958865"
            onClick={handleCallNow}
          >
            GỌI NGAY <Icon name="phone" className="m-0" />
          </Button>{' '}
          <strong className="text-blue">Hoặc để lại thông tin liên lạc bên dưới.</strong>
        </h2>
        <LandingPageLeadForm onSuccess={handleSuccess} />
        <br />
        <br />
        <Feedbacks />
        <br />
        <br />
        <br />

        <br />
        <br />
        <MobileNativeNav />
      </div>
    </>
  )
}

const MobileNativeNav = ({ onClose }) => {
  const [isShown, setIsShown] = useState(false)

  const handleCallNow = e => {
    const currentHour = Number(
      new Date()
        .toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hour12: false })
        .split(':')[0]
    )

    if (currentHour >= 8 && currentHour <= 21) {
      callHandler(e.currentTarget.dataset.phoneNumber)
      navigate('/vi/thank-you')
    } else {
      // eslint-disable-next-line no-alert
      window.alert(
        'Liên lạc ngoài giờ làm việc!!! Anh chị vui lòng để lại thông tin bên dưới, nhân viên tư vấn sẽ liên lạc. Giờ làm việc của nhân viên tư vấn 8AM-9PM (Giờ California)'
      )
    }
  }

  const handleSuccess = useCallback(() => {
    navigate('/vi/thank-you')
  }, [])

  const toggleViewForm = useCallback(() => {
    setIsShown(isShown => !isShown)
  }, [])

  const handleCloseLeadForm = useCallback(() => {
    setIsShown(false)
    onClose && onClose()
  }, [onClose])

  return (
    <div className="mobile-native-nav">
      <div className="px-2 py-2 d-flex justify-content-between align-items-center shadow">
        <Button onClick={toggleViewForm} className="my-2 rounded-pill shadow-sm" primary>
          LIÊN LẠC TÔI
        </Button>

        <Button
          className="call-now-btn rounded-pill text-white shadow-sm"
          data-phone-number="7148958865"
          onClick={handleCallNow}
        >
          GỌI NGAY <Icon name="phone" className="m-0" />
        </Button>
      </div>

      <Modal open={isShown}>
        <Modal.Header>
          <div className="d-flex justify-content-between">
            <img src={Logo} alt="contego logo" className="d-block" width="180px" height="auto" />
            <Button icon color="red" onClick={handleCloseLeadForm}>
              <Icon name="close" className="text-white" />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Content>
          <LandingPageLeadForm onSuccess={handleSuccess} />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default ProductDetails
